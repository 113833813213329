// Angular
import { Component, OnInit } from '@angular/core';
// RxJS
import { asyncScheduler } from 'rxjs';
// Service
import { LogoService } from '../../core/logo.service';

@Component({
  selector: 'app-redirect-screen',
  templateUrl: './redirect-screen.component.html',
  styleUrls: ['./redirect-screen.component.scss'],
})
export class RedirectScreenComponent implements OnInit {

  // Public 
  logoSrc: string;

  /**
   * Creates an instance of RedirectScreenComponent.
   * @param {LogoService} logoService
   * @memberof RedirectScreenComponent
   */
  constructor(
    private readonly logoService: LogoService
  ) { }

  /**
   * On init
   * @memberof RedirectScreenComponent
   */
  ngOnInit(): void {
    this.logoSrc = this.logoService.getLogoSrc();
    asyncScheduler.schedule(() => {
      window.location.href = '/dashboard';
    }, 2000);
  }
}
