// Angular
import { CommonModule } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';

// SVG inline
import { InlineSVGModule } from 'ng-inline-svg-2';

// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions';

// State
import { metaReducers, reducers } from './core/reducers';

// Components
import { AppComponent } from './app.component';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

// Partials
import { PartialsModule } from './modules/partials/partials.module';


// Auth
import { AuthService } from '@auth-service';
import { AuthModule } from './modules/auth/auth.module';

// Core Utils
import {
  HttpUtilsService,
  LayoutUtilsService,
  GlobalErrorHandler,
  MatPaginatorI18nService,
  CommonService,
  preloadI18nLocale,
  LocaleProvider
} from 'src/app/core/utils';

// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { environment } from '@environment';

// Service Worker
import { ServiceWorkerModule } from '@angular/service-worker';

// Material
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatPaginatorIntl } from '@angular/material/paginator';

// Transloco Module
import { TranslocoRootModule } from './transloco-root.module';
import { TranslocoService } from '@jsverse/transloco';
import { SplashScreenModule } from './_metronic/layout/components/splash-screen/splash-screen.module';

@NgModule({ declarations: [AppComponent],
    exports: [],
    bootstrap: [AppComponent], imports: [BrowserAnimationsModule,
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        HighlightModule,
        PartialsModule,
        CoreModule,
        OverlayModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        NgxPermissionsModule.forRoot(),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
        AuthModule,
        MatProgressSpinnerModule,
        InlineSVGModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        TranslocoRootModule,
        SplashScreenModule], providers: [
        Title,
        AuthService,
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: preloadI18nLocale,
            deps: [AuthService, CommonService, TranslocoService]
        },
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
                coreLibraryLoader: () => import('highlight.js/lib/core'),
                languages: {
                    xml: () => import('highlight.js/lib/languages/xml'),
                    typescript: () => import('highlight.js/lib/languages/typescript'),
                    scss: () => import('highlight.js/lib/languages/scss'),
                    json: () => import('highlight.js/lib/languages/json'),
                }
            }
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline'
            }
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 5000
            }
        },
        {
            provide: MatPaginatorIntl, useClass: MatPaginatorI18nService
        },
        LocaleProvider,
        environment.isSentryLoggingEnable ? { provide: ErrorHandler, useClass: GlobalErrorHandler } : [],
        // Services
        HttpUtilsService,
        LayoutUtilsService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
