// Angular
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Auth
import { AuthGuard, LoggedInAuthGuard } from './core/auth';
// Component
import { SplashScreenComponent } from './_metronic/layout/components/splash-screen/splash-screen.component';
import { RedirectScreenComponent } from './_metronic/layout/components/redirect-screen/redirect-screen.component';

// Routes
export const routes: Routes = [
  {
    path: 'auth',
    canActivate: [LoggedInAuthGuard],
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: 'refresh',
    component: SplashScreenComponent,
  },
  {
    path: 'redirect',
    component: RedirectScreenComponent
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./_metronic/layout/layout.module').then((m) => m.LayoutModule),
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableViewTransitions: true
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
