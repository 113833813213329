// Angular
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
// Core module
import { CoreModule } from '../../core/core.module';
import { HighlightModule } from 'ngx-highlightjs';
// CRUD Partials
import {
  LogoutConfirmationComponent,
  SwitchCompanyComponent,
  ConfirmUserAuthPasswordComponent,
  ViewRawPayloadDialogComponent
} from './dialog';

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { ClipboardModule } from '@angular/cdk/clipboard';
// Transloco Module
import { TranslocoRootModule } from '../../transloco-root.module';

@NgModule({
  declarations: [
    LogoutConfirmationComponent,
    SwitchCompanyComponent,
    ConfirmUserAuthPasswordComponent,
    ViewRawPayloadDialogComponent
  ],
  exports: [
    LogoutConfirmationComponent,
    SwitchCompanyComponent,
    ConfirmUserAuthPasswordComponent,
    ViewRawPayloadDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    MatButtonModule,
    MatInputModule,
    MatSnackBarModule,
    MatDialogModule,
    TranslocoRootModule,
    HighlightModule,
    ClipboardModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PartialsModule {
}
